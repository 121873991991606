import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as LocationIcon } from '@icons/wolfkit-light/map-pin-light.svg';
const ProfileLocation = ({ location = '' }) => {
    const { t } = useTranslation();
    const locationText = useMemo(() => {
        let res;
        if (!location)
            return res;
        const countriesList = t('profile.countries.countries_list', { ns: 'translation', returnObjects: true });
        return countriesList[location] || '';
    }, [location, t]);
    return (_jsx(IconText, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: LocationIcon })), text: locationText }));
};
export default ProfileLocation;
